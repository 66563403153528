import {Controller} from "stimulus";

export default class extends Controller {
    static targets = ["deferredUntil", "dueOn"]

    initialize() {
        $(this.deferredUntilTarget).on('apply.daterangepicker', this.deferredUntilChange.bind(this));
        $(this.dueOnTarget).on('apply.daterangepicker', this.dueOnChange.bind(this));
    }

    deferredUntilChange(event, picker) {
        const deferredUntilDate = picker.startDate;
        // if dueOn is empty or earlier than deferredUntil, set it to deferredUntil
        if ($(this.dueOnTarget).val() == "" || deferredUntilDate > $(this.dueOnTarget).data('daterangepicker').startDate) {
            this.setPickerValue(this.dueOnTarget, deferredUntilDate);
        }
    }

    dueOnChange(event, picker) {
        const dueOnDate = picker.startDate;
        // if deferredUntil is empty or later than dueOn, set deferredUntil to dueOn
        if ($(this.deferredUntilTarget).val() == "" || dueOnDate < $(this.deferredUntilTarget).data('daterangepicker').startDate) {
            this.setPickerValue(this.deferredUntilTarget, dueOnDate);
        }
    }

    setPickerValue(target, value) {
        $(target).val(value.format('MM/DD/YYYY'))
        $(target).data('daterangepicker').setStartDate(value);
        $(target).data('daterangepicker').setEndDate(value);
    }
}