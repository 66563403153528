import { Controller } from "stimulus";
import { enter, leave } from "el-transition";
import { createPopper } from '@popperjs/core';

export default class extends Controller {
    static targets = ["body", "reference"];

    connect() {
        const reference = document.getElementById(this.referenceTarget.dataset.id);
        const arrow = document.getElementById('arrow');

        createPopper(reference, this.bodyTarget, {
            placement: 'bottom-start'
        });

        enter(this.bodyTarget);

        document.addEventListener('turbo:submit-end', this.handleSubmit);
    }

    disconnect() {
        document.removeEventListener('turbo:submit-end', this.handleSubmit);
    };

    close() {
        leave(this.bodyTarget).then(() => {
            // Remove the popover element after the fade out so it doesn't blanket the screen
            this.element.remove();
        });

        // Remove src reference from parent frame element
        // Without this, turbo won't re-open the popover on subsequent clicks
        this.element.closest("turbo-frame").src = undefined;
    }

    handleKeyup(e) {
        if (e.code == "Escape") {
            this.close();
        };
    };

    handleClickOutside(e) {
        if (e.type === "click" && !(this.bodyTarget === e.target || this.bodyTarget.contains(e.target))) {
           this.close();
        };
    };
};