import { Controller } from "stimulus"
//import I18n from "i18n-js/index.js.erb"
require("daterangepicker/daterangepicker.css");

// requires jQuery, moment, might want to consider a vanilla JS alternative
import 'daterangepicker';

export default class extends Controller {
    static targets = [ "field" ]

    connect() {
        this.initPluginInstance()
    }

    disconnect() {
        this.teardownPluginInstance()
    }

    initPluginInstance() {
        $(this.fieldTarget).daterangepicker({
            autoUpdateInput: true,
            autoApply: true,
            linkedCalendars: false,
            showDropdowns: true
        })

        this.pluginMainEl = this.fieldTarget
        this.plugin = $(this.pluginMainEl).data('daterangepicker') // weird
    }

    teardownPluginInstance() {
        if (this.plugin === undefined) { return }

        $(this.pluginMainEl).off('apply.daterangepicker')
        $(this.pluginMainEl).off('cancel.daterangepicker')

        // revert to original markup, remove any event listeners
        this.plugin.remove()
    }
}