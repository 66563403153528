import { Controller } from "stimulus";
import { createPopper } from '@popperjs/core';

export default class extends Controller {
    static targets = ["icons", "assign"]

    open_assign(event) {
        createPopper(this.iconsTarget, this.assignTarget, {
            placement: 'bottom-start'
        });

        this.assignTarget.classList.remove("invisible");
    }

    close_assign(event) {
        this.assignTarget.classList.add("invisible");
    }

};