import { Controller } from "stimulus"
import { enter, leave } from "el-transition"

export default class extends Controller {
    static targets = ["menu", "button"];

    toggleMenu(event) {
        event.stopPropagation();
        if (this.menuTarget.classList.contains('hidden')) {
            enter(this.menuTarget);
        } else {
            leave(this.menuTarget);
        }
    }

    closeMenu(event) {
        if (!this.menuTarget.classList.contains('hidden')) {
            if (event.type === "keydown" && event.keyCode === 27) {
                leave(this.menuTarget);
            }
            if (event.type === "click" && !(this.menuTarget === event.target || this.menuTarget.contains(event.target))) {
                leave(this.menuTarget);
            }
        }
    }
}