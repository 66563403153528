import { Controller } from "stimulus"

export default class extends Controller {
    open_search() {
        const search_placeholder = document.getElementById("search_placeholder");
        const search_modal = document.getElementById("search_modal");
        const search_field = document.getElementById("events_search_query");

        search_placeholder.disabled = true;

        search_modal.classList.remove("invisible");

        setTimeout(() => {
            search_field.focus()
        }, 100);
    };

    close_search() {
        const search_placeholder = document.getElementById("search_placeholder");
        const search_modal = document.getElementById("search_modal");
        search_placeholder.disabled = false;

        search_modal.classList.add("invisible");
    };

    hide_results() {
        const search_field = document.getElementById("events_search_query");
        const search_results = document.getElementById("search_results_frame");

        if (search_field.value === "") {
            search_results.classList.add("hidden");
        };
    };

    show_results() {
        const search_field = document.getElementById("events_search_query");
        const search_results = document.getElementById("search_results_frame");

        if (search_field.value !== "") {
            search_results.classList.remove("hidden");
        };
    };

    handleKeyup(e) {
        if (e.code == "Escape") {
            this.close_search()
        }
    }
}